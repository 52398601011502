export default function Contact() {
  return (
    <div className="about-container">
      <div className="about-card center-text">
        <h3>Contact Me</h3>
        <h3>
          <a href="mailto: jgnewman1997@gmail.com">jgnewman1997@gmail.com</a>
        </h3>
      </div>
    </div>
  );
}

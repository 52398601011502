export default function About() {
  return (
    <div className="about-container">
      <div className="about-card center-text">
        <h1>Justin G. Newman</h1>
      </div>
      <div className="about-card">
        <h2>Work Experience</h2>
        <h3>Ford Credit via TEKsystems</h3>
        <h4>
          <i>Software Engineer:</i> August 2021 - Present
        </h4>
        <h3>CGI Inc.</h3>
        <h4>
          <i>Consultant:</i> June 2019 - August 2021
        </h4>
      </div>
      <div className="about-card">
        <h2>Certifications</h2>
        <h3>
          <a
            href="https://www.credly.com/badges/eb699659-b54b-4bfc-8ef4-1540fa84b0c8/linked_in_profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            Oracle Certified Associate, Java SE 8 Programmer
          </a>
        </h3>
        <h4>July 2019</h4>
      </div>
      <div className="about-card">
        <h2>Education</h2>
        <h3>Saint Vincent College</h3>
        <h4>
          <i>Major:</i> B.S. Engineering Science
        </h4>
        <h5>
          <i>Specialization:</i> Software Development
        </h5>
        <h5>
          <i>Minor:</i> Computer &amp; Information Sciences
        </h5>
        <h4>
          <i>Major:</i> B.A. Mathematics
        </h4>
      </div>
    </div>
  );
}
